import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ReportIcon } from '../icons/ReportIcon.jsx';
import { HarvestRequestIcon } from '../icons/HarvestRequestIcon.jsx';
import CurrentDate from '../Harvest-Request/CurrentDate';
import ImageIcon_green from '../icons/ImageIconGreen.png';
import ImageIcon_gray from '../icons/ImageIconGray.png';

const drawerWidth = 240;

const menuItems = [
    { name: 'Report', path: '/report', icon: ReportIcon },
    { name: 'Harvest Request', path: '/harvest-request', icon: HarvestRequestIcon },
    { name: 'Crop Inspection', path: '/image-tracker', icon: null },
];


const Sidebar = ({ isDrawerOpen, setHarvestRequest, harvestRequest }) => {
    const location = useLocation();
    const renderIcon = (item) => {
        if (item.name === 'Crop Inspection') {
            const isSelected = location.pathname === item.path;
            return (
                <img
                    src={isSelected ? ImageIcon_green : ImageIcon_gray}
                    alt="Image Tracker Icon"
                    style={{ width: 25, height: 25 }}
                />
            );
        }
        return <item.icon setHarvestRequest={setHarvestRequest} harvestRequest={harvestRequest} color={location.pathname === item.path ? '#02C39A' : '#717171'} />;
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    marginTop: 8,
                    borderRight: 0,
                },
            }}
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.name}
                        component={Link}
                        to={item.path}
                        selected={location.pathname === item.path}
                    >
                        <ListItemIcon>
                            {renderIcon(item)}
                        </ListItemIcon>
                        <ListItemText primary={`${item.name}  •`} />
                    </ListItem>
                ))}
            </List>
            <div><CurrentDate /></div>
        </Drawer>
    );
};

export default Sidebar;
