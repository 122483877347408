import React, { useEffect, useState } from "react";
import { fetchCompartments, fetchCrops, fetchGreenhouses, fetchOrganizationId, fetchSites, fetchLocations } from "../api";
import Dropdown from "./Dropdown";
import './CropSelector.css';
import unselectedNote from '../icons/unselected_note.png';
import selectedNote from '../icons/selected_note.png';

const getCurrentWeek = () => {
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const pastDaysOfYear = (currentDate - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

const CropSelector = ({ onChangeCrop, onChange, onCompartmentChange, onCompare, setCropId, onGreenhouseChange, onSiteChange, initialGreenhouseId, initialSiteId, showLocation, showCW, direction = "horizontal", onLocationChange, onWeekChange, initialCropId, showNote, initialLocationId, initialCompartmentId,
    handleWithNoteChange, isImageTracker = false }) => {

    const [organizationId, setOrganizationId] = useState('');

    const [site, setSite] = useState('');
    const [siteList, setSiteList] = useState([]);

    const [greenhouse, setGreenhouse] = useState('');
    const [greenhouseList, setGreenhouseList] = useState([]);

    const [compartment, setCompartment] = useState('');
    const [compartmentList, setCompartmentList] = useState([]);

    const [crop, setCrop] = useState('');
    const [cropList, setCropList] = useState([]);

    const [location, setLocation] = useState('All');
    const [locationList, setLocationList] = useState([]);

    const [weeks, setWeeks] = useState([]);
    const [currentWeek, setCurrentWeek] = useState(null);
    const [withNote, setWithNote] = useState(false);
    const [isHovered, setIsHovered] = useState(false);



    useEffect(() => {

        const weeksArray = Array.from({ length: getCurrentWeek() }, (_, i) => i + 1);
        setWeeks(weeksArray);
    }, []);

    const handleSiteChange = (event) => {
        const selectedSiteName = event.target.value;
        setSite(selectedSiteName);

        if (onSiteChange) {
            const selectedSiteObj = siteList.find(site => site.name === selectedSiteName);
            onSiteChange(selectedSiteObj?.id || null);
        }
    };


    const handleGreenhouseChange = (event) => {
        const selectedGreenhouseName = event.target.value;
        setGreenhouse(selectedGreenhouseName);

        if (onGreenhouseChange) {
            const selectedGreenhouseObj = greenhouseList.find(greenhouse => greenhouse.name === selectedGreenhouseName);
            onGreenhouseChange(selectedGreenhouseObj?.id || null);
        }
    };


    const handleCompartmentChange = (event) => {
        const selectedCompartmentName = event.target.value;
        setCompartment(selectedCompartmentName);

        if (onCompartmentChange) {
            const selectedCompartmentObj = compartmentList.find(comp => comp.name === selectedCompartmentName);
            onCompartmentChange(selectedCompartmentObj?.id || null);
        }
    };

    const handleCropChange = (event) => {
        cropList.filter(c => event.target.value === c.name).forEach(c => {
            setCrop(c);
            setCropId(c.id);
            setLocation('All');
            if (onLocationChange) {
                onLocationChange(null);
            }
        });
    };
    const handleLocationChange = (event) => {
        const selectedLocationName = event.target.value;
        setLocation(selectedLocationName);

        if (onLocationChange) {
            if (selectedLocationName === 'All') {
                onLocationChange(null);
            } else {
                const selectedLocationObj = locationList.find(loc => loc.name === selectedLocationName);
                onLocationChange(selectedLocationObj?.uuid || null);
            }
        }
    };

    const handleWeekChange = (event) => {
        const value = event.target.value;
        if (value === 'All') {
            setCurrentWeek(null);
            onWeekChange(null);
        } else {
            const selectedWeek = parseInt(value.replace('CW', ''), 10);
            setCurrentWeek(selectedWeek);
            onWeekChange(selectedWeek);
        }
    };

    const handleNoteClick = () => {
        const newWithNote = !withNote;
        setWithNote(newWithNote);
        handleWithNoteChange(newWithNote);
    };


    useEffect(() => {
        const fetchData = async () => {
            const organizationId = await fetchOrganizationId();
            setOrganizationId(organizationId);
        }
        fetchData().catch(err => console.error(err));
    }, [])

    useEffect(() => {
        if (!organizationId) {
            return;
        }

        const fetchData = async () => {
            const sites = await fetchSites(organizationId);
            setSiteList([...sites]);

            if (sites.length === 0) {
                return;
            }

            if (initialSiteId) {
                const initialSite = sites.find(site => site.id === initialSiteId);
                if (initialSite) {
                    setSite(initialSite.name);
                    return;
                }
            }
            setSite(sites[0].name);
        };

        fetchData().catch(err => console.error(err));
    }, [organizationId, initialSiteId]);


    useEffect(() => {
        if (!organizationId || !site || !siteList) {
            return;
        }

        const siteData = siteList.find(s => s.name === site);
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const fetchData = async () => {
            const greenhouses = await fetchGreenhouses(organizationId, siteId);
            setGreenhouseList([...greenhouses]);

            if (greenhouses.length === 0) {
                return;
            }

            if (initialGreenhouseId) {
                const initialGreenhouse = greenhouses.find(greenhouse => greenhouse.id === initialGreenhouseId);
                if (initialGreenhouse) {
                    setGreenhouse(initialGreenhouse.name);
                    return;
                }
            }

            setGreenhouse(greenhouses[0].name);
        };

        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, initialGreenhouseId]);


    useEffect(() => {
        if (!organizationId) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const greenhouseData = greenhouseList.filter(g => g.name === greenhouse)[0];
        if (!greenhouseData) {
            return;
        }
        const greenhouseId = greenhouseData.id;

        const fetchData = async () => {
            const compartments = await fetchCompartments(organizationId, siteId, greenhouseId);
            setCompartmentList(compartments);
            if (compartments.length === 0) {
                return;
            }
            if (initialCompartmentId) {
                const initialCompartment = compartments.find(comp => comp.id === initialCompartmentId);
                if (initialCompartment) {
                    setCompartment(initialCompartment.name);
                    return;
                }
            }
            setCompartment(compartments[0].name);
        }

        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, greenhouse, greenhouseList, initialCompartmentId])



    useEffect(() => {

        if (!organizationId) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const greenhouseData = greenhouseList.filter(g => g.name === greenhouse)[0];
        if (!greenhouseData) {
            return;
        }
        const greenhouseId = greenhouseData.id;

        const compartmentData = compartmentList.filter(c => c.name === compartment)[0];
        if (!compartmentData) {
            return;
        }
        const compartmentId = compartmentData.id;

        const fetchData = async () => {
            const crops = await fetchCrops(organizationId, siteId, greenhouseId, compartmentId, isImageTracker);
            setCropList(crops);

            if (crops.length === 0) {
                setCrop(null);
                setCropId(null);
                return;
            }
            const initialCrop = crops.find(crop => crop.id === initialCropId);
            if (initialCrop) {
                setCrop(initialCrop);
                setCropId(initialCrop.id);
            } else {

                setCrop(crops[0]);
                setCropId(crops[0].id);
            }
            if (onChangeCrop) {
                onChangeCrop(initialCrop ? initialCrop.id : crops[0].id);
            }
            if (onChange) {
                onChange(site, greenhouse, compartment, crops[0].name);
            }

        };
        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, greenhouse, greenhouseList, compartment, compartmentList, setCropId])

    //Location
    useEffect(() => {
        if (!crop || !crop.id) return;

        const fetchLocationData = async () => {
            try {
                const locations = await fetchLocations(crop.id);
                const updatedLocationList = [{ name: 'All', uuid: 'all' }, ...locations];
                setLocationList(updatedLocationList);

                if (locations.length === 0) {

                } else if (locations.length > 0 && !location) {

                    setLocation('All');
                }
                if (initialLocationId) {
                    const initialLocation = updatedLocationList.find(loc => loc.uuid === initialLocationId);
                    if (initialLocation) {
                        setLocation(initialLocation.name);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchLocationData();

    }, [crop, organizationId, site, siteList, greenhouse, greenhouseList, compartment, compartmentList, setCropId, location, initialLocationId]);
    {
        showNote && (
            <div
                className="with-note"
                onClick={() => {
                    setWithNote(true);

                }}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
                <img src={unselectedNote} alt="With note" />
                <span style={{ marginLeft: '8px' }}>With Note</span>
            </div>
        )
    }



    return (
        <div className={`crop-selector-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>

            <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                <Dropdown
                    name="Site"
                    values={siteList.map(s => `${s.name}`)}
                    selectedValue={`${site}`}
                    showColon={true}
                    handleChange={handleSiteChange}
                />
            </div>
            <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                <Dropdown
                    name="Greenhouse"
                    values={greenhouseList.map(g => g.name)}
                    selectedValue={greenhouse}
                    showColon={true}
                    handleChange={handleGreenhouseChange}
                />
            </div>
            <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                <Dropdown
                    name="Compartment"
                    values={compartmentList.map(c => c.name)}
                    selectedValue={compartment}
                    showColon={true}
                    handleChange={handleCompartmentChange}
                />
            </div>

            <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                <Dropdown
                    name="Fruits"
                    values={cropList.map(crop => crop ? crop.name : '')}
                    selectedValue={crop ? crop.name : ''}
                    showColon={true}
                    handleChange={handleCropChange}
                />
            </div>

            {showLocation && (
                <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                    <Dropdown
                        name="Location"
                        values={locationList.map(loc => loc.name)}
                        selectedValue={location || 'All'}
                        showColon={true}
                        handleChange={handleLocationChange}
                    />
                </div>
            )}
            {showCW && (
                <div className={`dropdown-container ${direction === "vertical" ? "vertical" : "horizontal"}`}>
                    <Dropdown
                        name="CW"
                        values={['All', ...weeks.map(week => `CW${week}`)]}
                        selectedValue={currentWeek !== null ? `CW${currentWeek}` : 'All'}
                        showColon={true}
                        handleChange={handleWeekChange}
                    />
                </div>
            )}
            {showNote && (
                <button
                    className={`with-note ${withNote ? 'active' : ''}`}
                    onClick={handleNoteClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <img src={isHovered || withNote ? selectedNote : unselectedNote} alt="With note" />
                    <span style={{ marginLeft: '8px' }}>With Note</span>
                </button>
            )}

        </div>
    )
};

export default CropSelector;